import Swiper from 'swiper/bundle';

const [d] = [document];

class Blocks {
    mainvisual() {
        const mySwiper = new Swiper(
            '.mainvisual-slider[data-plugin="mainSlider"]',
            {
                effect: 'fade',
                fadeEffect: {
                    crossFade: true,
                },
                autoplay: {
                    delay: 4000,
                    disableOnInteraction: false,
                },
                loop: true,
                loopAdditionalSlides: 1,
                speed: 1500,
            }
        );
    }
    newsslider() {
        const swiper = new Swiper(
            '.topics-slider[data-plugin="topicsSlider"]',
            {
                slidesPerView: 'auto',
                // slidesPerView: 1,
                loop: true,
                spaceBetween: 24,
                grabCursor: true,
                pagination: {
                    el: '.topics-slider .swiper-pagination',
                    clickable: true,
                },
                navigation: {
                    nextEl: '.topics-slider .swiper-button-next',
                    prevEl: '.topics-slider .swiper-button-prev',
                },
                breakpoints: {
                    576: {
                        // slidesPerView: 2,
                    },
                    768: {
                        // slidesPerView: 3,
                    },
                    1200: {
                        // slidesPerView: 4,
                    },
                },
                // grabCursor: true,
                // slidesPerView: 'auto',
                // loop: true,
                // spaceBetween: 12,
                // breakpoints: {
                //     900: {
                //         spaceBetween: 46,
                //     },
                // },
                // navigation: {
                //     nextEl: '.block-works .swiper-button-next',
                //     prevEl: '.block-works .swiper-button-prev',
                // },
                // pagination: {
                //     el: '.block-works .swiper-pagination',
                //     type: 'progressbar',
                // },
            }
        );
    }
}

const domReady = (TPL) => {
    let blocks = new Blocks();
    window.addEventListener('load', () => {
        blocks.mainvisual();
        blocks.newsslider();
    });
};

const jqInit = () => false;

export default function HOME() {
    // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
    d.addEventListener('DOMContentLoaded', domReady());
    $(() => jqInit());
}
